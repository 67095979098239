
import { Options, Vue } from 'vue-class-component';
import { EstimationClient, GarmentClient } from '@/services/Services';
import store from '@/store';

@Options({})
export default class editEstimationLayout extends Vue {

    estimationIdentifier: string = "";
    garmentConfigurationIdentifier: string = "";
    saveToGarmentConfiguration: boolean = false;
    
    loaded: boolean = false;
    created() {
        this.saveToGarmentConfiguration = !!this.$route.meta.saveToGarmentConfiguration;
        
        this.estimationIdentifier = this.$route.params.identifier.toString();

        let prom;
        if(this.saveToGarmentConfiguration){
            prom = store.state.estimationClient.getGarmentConfigurationIdentifier(this.estimationIdentifier);
        } else {
            prom = EstimationClient.getGarmentConfigurationIdentifier(this.estimationIdentifier);
        }
        prom
        .then(x => {
            this.garmentConfigurationIdentifier = x;
            (store.state.estimationClient as typeof EstimationClient).getUpdatedProductionPhases(this.estimationIdentifier)
            .then(phases => {
                this.$store.state.phases = phases;
                this.loaded = true;
            })
            // GarmentClient.getGarmentProductionPhases(x)
            // .then(phases => {
            //     this.$store.state.phases = phases;
            //     this.loaded = true;
            // });
        })
    }
}
