import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!

  return (_ctx.loaded)
    ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0,
        estimationIdentifier: _ctx.estimationIdentifier,
        garmentConfigurationIdentifier: _ctx.garmentConfigurationIdentifier,
        saveToGarmentConfiguration: _ctx.saveToGarmentConfiguration
      }, null, 8, ["estimationIdentifier", "garmentConfigurationIdentifier", "saveToGarmentConfiguration"]))
    : _createCommentVNode("", true)
}